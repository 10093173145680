#root {
  /* max-width: 1280px; */
  margin: 0 auto;
  padding: 2rem;
  text-align: center;
}

.logo-container {
  min-width: 80vw;
  width: 100%;
  height: 100%;
  min-height: 65vh;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 65vh;
  overflow: hidden;
}
.video-container {
  min-width: 80vw;
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-container video {
  width: 100%;
  height: 100%;
  max-height: 65vh;
  object-fit: contain;
}
* {
  box-sizing: border-box;
}

.audio-player button {
  border-radius: 8px;
  border: 2px solid #f1691a;
  padding: 0.1em 1.2em;
  font-size: 1em;
  font-weight: 600;
  font-family: inherit;
  background-color: #ffffff;
  cursor: pointer;
  transition: all 0.5s;
  color: #000000;
  margin: 0 auto;
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  height: 36px;
}

.audio-player button:hover {
  border-color: transparent;
  background-color: #000;
  color: #f1691a;
}

.controller input {
  width: 100%;
}

.controller {
  display: flex;
  height: 40px;
  align-items: center;
  padding: 5px;
}
.controller h5 {
  white-space: nowrap;
  color: white;
  padding-right: 12px;
}

.controller button {
  color: #fdfdfd;
  border-color: #74676700;
  background-color: #35323210;
  outline: none;
  padding: 0;
}

.controller .play-btn {
  width: 50px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
  background-color: #f1691a;
  color: #000000;
  border: 2px solid #f1691a;
  margin-right: 12px;
}
.controller .play-btn:hover {
  background-color: #00000000;
  color: #f1691a;
}

.controller .forward-controller,
.controller .backward-controller {
  width: 70px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
  background-color: #080808;
  color: #a7a7a7;
  border: 2px solid #a7a7a7;
  margin-right: 12px;
}
.controller .forward-controller:hover,
.controller .backward-controller:hover {
  background-color: #00000000;
  color: #dddddd;
}

.controller .slider {
  -webkit-appearance: none;
  width: 100%;
  height: 14px;
  border-radius: 8px;
  background-color: #64606010;
  outline: none;
  margin-right: 12px;
  border: 1px solid #d8d5d51f;
}

.controller .slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #e9e6e3;
  cursor: pointer;
  margin-top: 0px;
}

.fullscreen-btn:hover {
  transform: scale(1.1);
  background-color: #00000000;
  color: #f1691a;
  fill: #f1691a;
}

#vdo-frwd {
  display: none;
}

.restart-btn {
  padding: 0 4px !important;
  background-color: transparent !important;
}

.restart-btn svg {
  fill: #dddddd8c;
}
.restart-btn:hover svg {
  fill: #f1691a;
}

.fullscreen-btn {
  padding-left: 8px !important;
  background-color: transparent !important;
}
.fullscreen-btn svg {
  fill: #dddddd8c;
}

.fullscreen-btn:hover svg {
  fill: #f1691a;
}

.wrapper {
  padding: 36px 24px;
  border: 2px solid #ffffff;
  border-radius: 12px;
  background-color: #000000;
  box-shadow: 1px 8px 20px 4px #00000054;
}

/* Hande Button disabled */

.controller button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
